<template>
  <div>
    <div
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      v-if="loading"
    >
      <t-loader extraClass="border-primary-900 w-12 h-12" />
    </div>

    <div v-if="!loading" class="pt-2">
      <div
        class="w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-2 gap-y-4 mb-4"
      >
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Entrenadores',
              quantity: data.counters.coaches.total,
              icon: 'chalkboard-teacher',
              change: data.counters.coaches.actives,
              changeVar: 'torneos activos'
            }"
            bgColor="bg-orange-1100"
          />
        </div>

        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Academias',
              quantity: data.counters.academies.total,
              icon: 'shield-alt',
              change: data.counters.academies.actives,
              changeVar: 'equipos activos'
            }"
            bgColor="bg-red-1100"
          />
        </div>

        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Torneos',
              quantity: data.counters.tournaments.total,
              icon: 'trophy',
              change: data.counters.tournaments.actives,
              changeVar: 'torneos activos'
            }"
            bgColor="bg-green-1100"
          />
        </div>
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Partidos',
              quantity: data.counters.games.total,
              icon: 'volleyball-ball',
              change: data.counters.games.actives,
              changeVar: 'partidos activos'
            }"
            bgColor="bg-yellow-1100"
          />
        </div>
      </div>

      <div class="flex flex-col md:flex-row align-center justify-between my-4">
        <div class="flex flex-col md:flex-row items-center">
          <div class="my-auto mx-2 w-full md:w-1/6">
            <searchbar
              placeholder="Buscar"
              v-model="query"
              @changeQuery="changeQuery"
            ></searchbar>
          </div>
          <t-input-group class="mx-2">
            <t-select
              v-model="parameters.active"
              placeholder="Estatus"
              :options="[{ id: 1, name: 'Activos' },{ id: 0, name: 'Inactivos' }]"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
          <t-input-group class="mx-2">
            <t-select
              v-model="parameters.verified"
              placeholder="Verificación"
              :options="[{ id: 1, name: 'Verificados' },{ id: 0, name: 'Pendientes' },{ id: 2, name: 'Rechazados' }]"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
          <t-input-group class="mx-2">
            <t-select
              v-model="parameters.id_carnet_type"
              placeholder="Carnet"
              :options="cardTypes"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
          <t-input-group class="mx-2">
            <t-select
              v-model="parameters.id_academy"
              placeholder="Equipo"
              :options="academies"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
          <!--<t-input-group class="mx-2 w-full md:w-1/5">
            <t-select
              v-model="parameters.gender"
              placeholder="Sexo"
              :options="genders"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>-->
        </div>

        <t-button
          variant="error"
          class="my-auto mr-2 h-fit py-2 lg:text-sm xl:text-md"
          @click="$router.push('/entrenadores/crear')"
        >
          Crear entrenador
        </t-button>
      </div>

      <div class="w-full">
        <basic-table
          ref="list-table"
          module="coach"
          :headers="headers"
          :url="'entrenadores'"
          :data="coaches"
          :route="'/coach'"
          :tableName="'ENTRENADORES'"
          :parameters="parameters"
          @deactivate="deactivate"
          @activate="activate"
          @erase="erase"
        />
      </div>
    </div>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import StatCard from "@/components/Statistics/Card.vue"
import Searchbar from "@/components/Searchbar"
export default {
  components: {
    StatCard,
    Searchbar
  },
  data() {
    return {
      loading: true,
      submitLoader: false,
      headers: [
        "",
        "Nombre",
        "Equipo",
        "Sexo",
        "Teléfono",
        "Correo",
        "Instagram",
        "Acciones"
      ],
      data: {
        counters: {
          tournaments: {},
          academies: {},
          players: {},
          coaches: {},
          notices: {},
          games: {},
          categories: {},
          titles: {}
        }
      },
      query: "",
      parameters: {
        active: 1,
        order_key: 'first_name',
        order_value: 'last_name',
        id_category: null,
        id_academy: null,
        //gender: null,
        verified: 1
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    },
    cardTypes() {
      return this.$store.getters["global/getItems"](
        "cardType",
        "unpaginated_elements"
      )
    },
    academies() {
      return this.$store.getters["global/getItems"](
        "academy",
        "unpaginated_elements"
      )
    },
    genders() {
      return [
        {
          id: 1,
          name: "Femenino"
        },
        {
          id: 2,
          name: "Masculino"
        }
      ]
    },
    coaches() {
      return this.$store.getters["global/getItems"]("coach", "elements").map(
        (el) => ({
          id: el.id,
          image: el.url_profile_picture,
          name: el.first_name + " " + el.last_name,
          academy: el.academy ? el.academy.name : "No asignado",
          gender: el.gender === 1 ? "Femenino" : "Masculino",
          phone: el.phone,
          email: el.email,
          instagram: el.instagram,
          active: el.active,
          verified: el.verified
        })
      )
    }
  },
  methods: {
    changeQuery(value) {
      if (this.$refs["list-table"]) this.$refs["list-table"].changeQuery(value)
    },
    getCounters() {
      this.$store
        .dispatch("global/getItems", {
          route: "user/admin/counters",
          noPaginate: true
        })
        .then((response) => {
          this.data = response.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    getItems(route, module, master) {
      this.$store
        .dispatch("global/getItems", {
          module: module,
          route: route,
          noPaginate: true,
          isMaster: master,
          params: {
            active: 1,
            order_key: "name",
            order_value: "asc"
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    deactivate() {
      this.data.counters.coaches.actives--
    },
    activate() {
      this.data.counters.coaches.actives++
    },
    erase() {
      this.data.counters.coaches.total--
      this.data.counters.coaches.actives--
    }
  },
  created() {
    this.getCounters()
    this.getItems("/carnet_type/all", "cardType", true)
    this.getItems("/academy/all", "academy", false)
  }
}
</script>
